import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/admin/report`

const getLatestXeroUpdate = () => {
  return axios.get(api + '/getLatestXeroUpdate')
}
const getCommissionPdf = (args) => {
  return axios.post(api + '/getCommissionPdf', args, { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = `Artist_Commission${args.artist ? '_' + args.artist : ''}_${args.dateRange[0]}_${args.dateRange[1]}.pdf`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}

const loadXeroLineItemsWithProduct = (args) => {
  return axios.post(api + '/loadXeroLineItemsWithProduct', args)
}
const loadXeroCustomerOrderSummary = () => {
  return axios.post(api + '/loadXeroCustomerOrderSummary')
}
const loadProductSales = (args) => {
  return axios.post(api + '/loadProductSales', args)
}
const loadProductSalesByItemCode = (args) => {
  return axios.post(api + '/loadProductSalesByItemCode', args)
}
const loadProductSalesByArtwork = (args) => {
  return axios.post(api + '/loadProductSalesByArtwork', args)
}
const loadProductGeoSales = (args) => {
  return axios.post(api + '/loadProductGeoSales', args)
}
const loadProductSalesPerformance = (args) => {
  return axios.post(api + '/loadProductSalesPerformance', args)
}
const loadArtistGeoSales = (args) => {
  return axios.post(api + '/loadArtistGeoSales', args)
}
const loadCustomerSales = (args) => {
  return axios.post(api + '/loadCustomerSales', args)
}
const loadArtistSales = (args) => {
  return axios.post(api + '/loadArtistSales', args)
}
const loadArtistCommission = (args) => {
  return axios.post(api + '/loadArtistCommission', args)
}
const getXeroItemCodes = () => {
  return axios.post(api + '/getXeroItemCodes')
}

const loadUnsoldProducts = () => {
  return axios.post(api + '/loadUnsoldProducts')
}
const loadArtistUnsoldProducts = (artistCode) => {
  return axios.post(api + '/loadArtistUnsoldProducts', {artistCode: artistCode})
}
const loadArtworkUnsoldProducts = (artistCode) => {
  return axios.post(api + '/loadArtworkUnsoldProducts', {artistCode: artistCode})
}

export default {
  getLatestXeroUpdate,
  getCommissionPdf,
  loadXeroLineItemsWithProduct,
  loadXeroCustomerOrderSummary,
  loadProductSales,
  loadProductSalesByItemCode,
  loadProductSalesByArtwork,
  loadProductSalesPerformance,
  loadProductGeoSales,
  loadArtistGeoSales,
  loadArtistCommission,
  loadCustomerSales,
  loadArtistSales,
  getXeroItemCodes,
  loadUnsoldProducts,
  loadArtistUnsoldProducts,
  loadArtworkUnsoldProducts
}

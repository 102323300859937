<template>
  <v-container fluid>
    <v-card flat>
      <h2>Custom Products Order Summary ( total customers {{totalCustomers}}, total revenue £{{totalRevenue.toFixed()}} ) </h2>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="summary"
          :disable-pagination="true"
          :sort-by="['totalAmount']"
          :sort-desc="[true]"
          :hide-default-footer=true
          :loading="loadingData"
          loading-text="Loading customers... Please wait"
          dense>
            <template v-slot:item.totalAmount="{ item }">
              <span>£{{ item.totalAmount.toFixed(2)}}</span>
            </template>
            <!-- <template v-slot:item.category="{ item }">
              {{ item.contact.marketingCategory}}
            </template> -->
            <template v-slot:item.orders[0].date="{ item }">
            <span>{{ new Date(item.orders[0].date).toLocaleDateString()}}</span>
            </template>
          </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ReportApi from '@/api/admin/report'
import _ from 'lodash'

export default {
  data() {
    return {
      panel: 0,
      loadingData: true,
      imageUrl: process.env.VUE_APP_IMG_URL,
      artists: {},
      rawData: [],
      summary: [],
      customers: [],
      totalCustomers: 0,
      totalRevenue: 0,
      orders: null,
      headers: [
        {
          text: 'Name',
          value: 'customerName'
        },
        {
          text: 'Category',
          value: 'contact.marketingCategory'
        },
        {
          text: 'Total orders',
          value: 'numInvoices'
        },
        {
          text: 'Most recent order',
          value: 'orders[0].date'
        },
        // {
        //   text: 'Num Items',
        //   value: 'totalItems'
        // },
        {
          text: 'Total amount',
          value: 'totalAmount'
        }
      ]
    }
  },
  computed: {
  },
  methods: {
    getData() {
      ReportApi.loadXeroCustomerOrderSummary().then((results) => {
        this.orders = results.data
        this.customers = _.groupBy(this.orders, 'customer')
        this.totalCustomers = Object.keys(this.customers).length
        for (const customer in this.customers) {
          let totalItems = 0
          let totalAmount = 0
          const orders = []
          let contact
          const numInvoices = this.customers[customer].length
          this.customers[customer].map((item, index) => {
            totalItems += item.numItems
            totalAmount += item.total
            contact = item.contact
            orders.push(item)
          })
          this.summary.push({ customerName: customer, contact: contact, totalItems, totalAmount, numInvoices, orders })
        }
        for ( const item of this.summary) {
          this.totalRevenue += item.totalAmount
        }
      this.loadingData = false
      })
    }
  },
  async created() {
    this.getData()
  }
}
</script>
